/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  StatisticsWidget5,
  ListsWidget3,
  TablesWidget10,
} from '../../../_metronic/partials/widgets'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row'>
      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3'>
      <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-0'
            svgIcon='chart-simple'
            color='white'
            iconColor='primary'
            title='500M$'
            description='SAP UI Progress'
          />

      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3'>
      <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-0'
            svgIcon='cheque'
            color='dark'
            iconColor='white'
            title='+3000'
            titleColor='white'
            description='New Customers'
            descriptionColor='white'
          />
       
      </div>
      {/* end::Col */}


       {/* begin::Col */}
       <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3'>
       <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-0'
            svgIcon='briefcase'
            color='warning'
            iconColor='white'
            title='$50,000'
            titleColor='white'
            description='Milestone Reached'
            descriptionColor='white'
          />
      </div>
      {/* end::Col */}


       {/* begin::Col */}
       <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3'>
       <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-0'
            svgIcon='chart-pie-simple'
            color='info'
            iconColor='white'
            title='$50,000'
            titleColor='white'
            description='Milestone Reached'
            descriptionColor='white'
          />
      </div>
      {/* end::Col */}


     
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gx-5 gx-xl-10'>
      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}

   

   
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
