const QUERIES = {
  USERS_LIST: 'users-list',
  TROLLY_CATEGORY_LIST: 'trolly-category-list',
  TROLLY: 'trolly-list',
  PARTS_STOCK: 'parts-stock-list',
  PARTS_CATEGORY: 'parts-category-list',
  PARTS: 'parts-list',
  SPECIFICATION: 'specification-list',
  PROJECTS: 'projects-list',
}

export {QUERIES}
