/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-bar-chart'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            System Management
          </span>
        </div>
      </div>

      <SidebarMenuItem
        to='/apps/projects-management/projects'
        icon='abstract-28'
        title='Projects'
        fontIcon='bi-file-earmark'
      />

      <SidebarMenuItemWithSub to='#' title='Trolly' icon='element-11' fontIcon='bi-file-earmark'>
        <SidebarMenuItem to='/apps/trolly-management/trolly' title='Trolly' hasBullet={true} />
        <SidebarMenuItem to='/apps/trolly-management/category' title='Category' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='#' title='Parts' icon='element-11' fontIcon='bi-file-earmark'>
        <SidebarMenuItem to='/apps/parts-management/parts' title='Parts' hasBullet={true} />
        <SidebarMenuItem to='/apps/parts-management/category' title='Category' hasBullet={true} />
        <SidebarMenuItem to='/apps/parts-management/stock' title='Stock' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-people'
      />

      <SidebarMenuItem
        to='/apps/specification-management/specification'
        icon='abstract-28'
        title='Specification'
        fontIcon='bi-file-earmark'
      />
    </>
  )
}

export {SidebarMenuMain}
