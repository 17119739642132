import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      <MenuItem title='Projects' to='/apps/projects-management/projects' />
      <MenuItem title='Trolly' to='/apps/trolly-management/trolly' />
      <MenuItem title='Users' to='/apps/user-management/users' />
    </>
  )
}
